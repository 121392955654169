<template lang="pug">
.help.d-flex
    v-row.flex-grow-1( no-gutters )
        v-card(light)
            v-card-text.brand.white--text.py-2 DevOps Help System
            v-card-subtitle
                v-btn.mr-4.brand.white--text(
                    v-for="item in items"
                    :key="item.header"
                    color='blue'
                    @click='getHelpFile(item)'
                ) {{ item.header }}
            v-card-text(v-if="helpContent" )
                v-sheet.rounded-lg.pt-5.pl-5(
                    v-html='helpContent'
                    elevation="5"
                )

</template>


<script lang="js">

export default {
    name: 'DevopsHelp',
    data () {
        return {
            helpContent: "",
            items: [
                {header: 'Database Users', folder: 'devops', file: 'database-users'},
                {header: 'Customer Installer', folder: 'devops', file: 'installer'},
                {header: 'Customer Site File', folder: 'devops', file: 'site-file'},
                {header: 'Server Setup', folder: 'devops', file: 'server-setup'},
            ],

        };
    },
    methods: {
        getHelpFile (data){
            this.helpContent = "Loading...";
            this.socketEmit('getHelpFile', {folderName: data.folder, fileName: data.file}, (data) => {
                this.helpContent = data.content.replace(/\n/g,"") || "<h2>No help content found</h2><br>";
            });
        }
    }
};
</script>
